<template>
    <SimpleTable endpoint="service" :aditionalColumns="headers" hideEdit hideNew>
        <template>
            <h1>Atendimentos</h1>
            <v-divider class="mb-4"></v-divider>
            <v-alert icon="warning" prominent text type="error"> A modificação de atendimento somente é permitida pelo ADMINISTRADOR do sistema. </v-alert>
        </template>

        <template v-slot:extra-column="item">
            <div class="text-center">
                <v-chip class="ma-0" outlined color="success"> {{ $moment(item.deliveryDate).format("DD/MM/YYYY") }} </v-chip>
            </div>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: { SimpleTable },

    data() {
        return {
            headers: [
                { text: this.$t("Data Entrega"), value: "extra-column", align: "right", sortable: false },
                { text: this.$t("Cliente"), value: "client", align: "right", sortable: true },
                { text: this.$t("Unidade"), value: "store", align: "right", sortable: true },
            ],
        };
    },
};
</script>